<template>
    <div>
        <div>
            <validation-observer ref="buyerRegisterValidation" name="RegisterBuyer">
                <b-card>
                    <b-modal v-model="showModal" hide-footer>
                        <p>{{ $t('please_complete_your_registration_and_follow_the_steps_below') }}</p>
                    </b-modal>
                    <h3>{{ $t("add_buyer_details") }}</h3>
                    <p>
                        {{ $t("we_need_some_basic_data_from_you") }}
                    </p>
                    <b-row>
                        <b-col cols="12">
                            <b-button :variant="!displayCompany ? 'primary' : 'outline-primary'" class="mb-1" @click="displayCompany = false">
                                <feather-icon icon="user"></feather-icon>
                                {{ $t("i_am_investing_as_private_person") }}
                            </b-button>
                            <b-button
                                :variant="displayCompany ? 'primary' : 'outline-primary'"
                                @click="displayCompany = true"
                                class="ml-xl-1 ml-lg-1 ml-md-1 ml-sx-0 mb-1"
                            >
                                <feather-icon icon="briefcase"></feather-icon>
                                {{ $t("i_am_investing_in_the_name_of_a_company") }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row class="m-2">
                        <label for="">
                            {{ $t('buyer_onboarding_accept_checkbox_text_1') }}
                            <br>
                            <br>
                            {{ $t('buyer_onboarding_accept_checkbox_text_2') }}
                            <br>
                            <br>
                            {{ $t('buyer_onboarding_accept_checkbox_text_3') }}
                            <br>
                            <br>
                            {{ $t('buyer_onboarding_accept_checkbox_text_4') }}
                            <br>
                            <br>
                            {{ $t('buyer_onboarding_accept_checkbox_text_5') }}

                        </label>
                    </b-row>
                    <b-row class="mx-2 mb-1">
                        <b-form-checkbox
                            v-model="acceptCheckbox"
                        >
                            Accept
                        </b-form-checkbox>


                    </b-row>

                    <small style="color: red;" class="m-2" v-if="acceptCheckboxErrorMessage">{{ acceptCheckboxErrorMessage }}</small>

                    <b-row class="p-1 d-flex">
                        <span class=" mr-1" style="font-size:16px"> Are you sure you want to continue
                        <strong v-if="displayCompany">   {{ $t("investing_in_the_name_of_a_company") }}</strong>
                        <strong v-else>  {{ $t("investing_as_private_person") }}</strong> ?</span>
                        <span><b-button size="sm" @click="completeIdentification()">Yes</b-button></span>
                    </b-row>
                    <b-row>
                        <small class="pl-1">*{{ $t("confirm_your_choice") }}</small>
                    </b-row>

                    <b-card>
                        <span class="mt-2" v-if="choiceConfirmed">
                            <hr>
                            <br>
                            <div id="sumsub-websdk-container"></div>

                            <b-col cols="12" xl="12" md="12" lg="12" class="justify-center text-center mt-2">
                                <b-button @click="checkStatus()" variant="outline-primary" class="mb-2">
                                    Check Status
                                </b-button>
                            </b-col>
                        </span>
                        <b-col v-if="showLoader" cols="12" xl="6" md="6" lg="6">
                            <img src="/new-loader.svg"/>
                        </b-col>
                    </b-card>
                </b-card>
            </validation-observer>
        </div>
    </div>
</template>

<script>
  import snsWebSdk from '@sumsub/websdk';
  import {ValidationProvider, ValidationObserver} from "vee-validate";
  import {VueAutosuggest} from "vue-autosuggest";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import {required, email} from "@validations";
  import Cleave from "vue-cleave-component";
  import moment from "moment";
  import {phone} from "phone";
  import i18n from "@/libs/i18n";
  import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
  import {
    BAlert,
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
  } from "bootstrap-vue";

  export default {
    components: {
      PDFViewer,
      BCard,
      BRow,
      BCol,
      BForm,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BInputGroup,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      BAlert,
      BInputGroupPrepend,
      ValidationProvider,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
    },
    name: "AddBuyer",
    data() {
      return {
        acceptCheckbox: false,
        acceptCheckboxErrorMessage: null,
        showModal: true,
        displayCompany: false,
        choiceConfirmed: false,
        sumsubToken: null,
        levelName: null,
        sumsubUserId: null,
        showLoader: false,
        sumsub_id: null,
        showMtanVerification: false,
        verified_at : '',
        mtanValue : '',
      };
    },
    computed: {},
    created() {
      this.getUserInfo();
      const that = this;
      // setTimeout(function () {
      //   that.checkStatus();
      // }, 1000);
    },
    methods: {

      // getUser() {
      //   this.$http.post("/buyer/show", {}).then((res) => {
      //     if(res.data.value.status == 1){
      //       // this.$router.push('/')
      //     }
      //     this.verified_at = res.data.value.verified_at;
      //     this.mtanValue = res.data.value.mTan;
      //   });
      // },


      confirmChoice() {
        this.choiceConfirmed = true;
      },
      toTerms() {
        if (this.$i18n.locale == "en") {
          window.open("https://www.aequitex.com/terms-condition", "_blank");
        } else {
          window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
        }
      },

      notActiveNow() {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("not_yet_active_for_companies"),
            icon: "ErrorIcon",
            variant: "error",
          },
        });
      },
      onSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.form));
      },
      onReset(event) {
        event.preventDefault();
        this.country_id = null;
      },
      completeIdentification() {
        if(!this.acceptCheckbox) {
          this.acceptCheckboxErrorMessage = 'accept field is required'
          return
        }
        this.choiceConfirmed = true;
        this.showLoader = true;
        if (this.displayCompany) {
          this.levelName = 'institutional-investor/buyer'
        } else if (!this.displayCompany) {
          this.levelName = 'individual-investors'
        }
        this.$http.post(`/buyer/getSumsubClientId`, {role: this.levelName}).then((res) => {
          if (res.data) {
            this.sumsubUserId = res.data.value.clientId;
            setTimeout(() => {
              this.$http.post(`/sumsub/token?user_id=${this.$store.state.auth.user.id}&sumsub_id=${this.sumsubUserId}&level=${this.levelName}`).then((res) => {
                if (res.data) {

                  this.sumsubToken = res.data.token;
                  console.log(this.sumsubToken)
                  // this.launchWebSdk(this.sumsubToken, this.$store.state.auth.user.email, this.$store.state.auth.user.tel);
                  this.launchWebSdk();
                }
              });
            }, 2000);
          }
        });
      },
      launchWebSdk(accessToken, applicantEmail, applicantPhone) {
        accessToken = this.sumsubToken;
        let snsWebSdkInstance = snsWebSdk.init(
            accessToken,
            () => this.getNewAccessToken()
        )
            .withConf({
              lang: 'en',
              email: applicantEmail,
              phone: applicantPhone,
              // externalUserId: `Prod-${this.$store.state.auth.user.id}`,
              // id: this.sumsubUserId
            })
            .withOptions({addViewportTag: false, adaptIframeHeight: true})
            .on('idCheck.onStepCompleted', (payload) => {
              console.log('onStepCompleted', payload)
            })
            .on('idCheck.onError', (error) => {
              console.log('onError', error)
            })
            .build();
        snsWebSdkInstance.launch('#sumsub-websdk-container');
        this.showLoader = false;
      },
      getNewAccessToken() {
        this.$http.post(`/sumsub/token?user_id=${this.$store.state.auth.user.id}&sumsub_id=${this.sumsubUserId}&level=${this.levelName}`).then((res) => {
          if (res.data) {
            this.sumsubToken = res.data.token;
          }
        });
        return Promise.resolve(this.sumsubToken)
      },
      getUserInfo() {
        this.$http
            .post(`/company/show/${this.$store.state.auth.user.company_id}`)
            .then((res) => {

              if (res && res.data.value && res.data.value.main_contact && res.data.value.main_contact.sumsub_id != null) {
                this.sumsub_id = res.data.value.main_contact.sumsub_id;
                this.verified_at = res.data.value.main_contact.verified_at;
                this.mtanValue = res.data.value.main_contact.mTan;
              }
              if(res && res.data.value && res.data.value.main_contact &&  !res.data.value.main_contact.status) {
                this.checkStatus()
              }
            });
      },
      checkStatus() {
        this.$http.post(`/buyer/getSumsubClientId/status`)
            .then((res) => {
              if (res.data.value && res.data.value.status && res.data.value.status.review && res.data.value.status.review.reviewStatus) {
                if (res.data.value.status.review.reviewStatus == 'completed') {
                  if(this.verified_at == null && this.mtanValue == null) {
                    if (res.data.value.status.review.levelName == 'individual-investors') {
                      this.$router.push("/cookpit/task/activate-account");
                    } else if (res.data.value.status.review.levelName == 'institutional-investor/buyer') {
                      this.$router.push("/buyer/upload-bod");
                    }
                  }
                }
              }

            });
      },
    },
  };
</script>

<style scoped>
</style>
